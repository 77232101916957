html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

header {
  .button {
    margin-top: 15px;
    margin-top: 15px;
  }
  // padding: 20px !important;
}

.dark-bg {
  p, h1, h2, h3, h4, h5, h6, input {
    color: #fff;
  }
  p.dim, h1.dim, h2.dim, h3.dim, h4.dim, h5.dim, h6.dim {
    color: #6c7686;
  }

  background-color: #2d3436;
}

.label {
  display: inline;
  padding: .1em .2em .1em;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  margin: 0px 0.3rem;
}

.label-outline {
  border: solid 1px lighten($color-primary, 20%);
}

.label-primary {
  background-color: $color-primary;
  border: solid 1px darken($color-primary, 10%);
  color: white;
}

/////// Footer

footer {
  padding: 2rem;
}

#subscribe-form {
  // background-color: #2d3436;
  padding: 2rem;

  input {
    height: 5.8rem;
    font-size: 2.2rem;
  }

  .button {
    font-size: 1.6rem;
  }
}

.subscribe-btn {
  width: 100%;
}

.text-center {
  text-align: center;
}

.social-icon {
  max-width: 50px;
}
