h1 {
  margin-top: 30px;
}

.post-list {
  list-style: none;
  p {
    font-size: 2rem;
  }
  h2 {
    margin-bottom: 1.0rem;
  }
  hr {
    width: 50%;
    margin: auto;
    border: 0.5px solid $color-quinary;
  }
}

article {
  font-size: 2rem;
}

article.container {
  margin-bottom: 5rem;
}