/* Larger than mobile screen */
@media (max-width: 40.0rem) {
  .text-center-sm {
    text-align: center;
  }
}

/* Larger than mobile screen */
@media (min-width: 40.0rem) {
  h1 {
    font-size: 7rem;
  }
  .float-right-md {
    float: right;
  }
}

/* Larger than tablet screen */
@media (min-width: 80.0rem) {
  h1 {
    font-size: 8rem;
  }
}

/* Larger than desktop screen */
@media (min-width: 120.0rem) {

}