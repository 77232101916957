#jumbotron {
  width: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.3);
  background-image: url('/assets/images/homepage-img.jpeg');
  // -webit-filter: grayscale(20%);
  // filter: grayscale(20%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 75vh;
  border-top: #989898 1px solid;
  border-bottom: #989898 1px solid;
  margin-bottom: 5rem;

  h1 {
    margin-top: 25vh;
    color: $color-tertiary;
    text-shadow: 2px 2px 1px #333;
    font-weight: bold;
  }
}

.about-profile {
  border-radius: 150px;
  border: solid 4px white;
  box-shadow: 1px 1px 3px;
  max-width: 200px;
}