@charset "utf-8";

$color-initial:    #fff;
$color-primary:    #9b4dca;
$color-secondary:  #606c76;
$color-tertiary:   #f4f5f6;
$color-quaternary: #d1d1d1;
$color-quinary:    #e1e1e1;

@import "normalize.css/normalize";
@import "milligram/src/milligram";
@import "responsive";
@import "global";
@import "blog";
@import "pages";