
// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-initial: #fff !default;
$color-primary: #9b4dca !default;
$color-secondary: #606c76 !default;
$color-tertiary: #f4f5f6 !default;
$color-quaternary: #d1d1d1 !default;
$color-quinary: #e1e1e1 !default;
